<template>
  <div>
    <!-- <app-bar ref="refAppBar"></app-bar> -->
    <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
      過去一週間分の更新履歴を表示しています。
    </div>
    <v-card elevation="0">
      <v-card-title>
        <v-checkbox
          v-model="hideWorking"
          label="勤務状態を非表示"
          @change="hideWorkingFilter"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（キーワード検索）"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :mobile-breakpoint="0"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <template v-slot:body="{ items: desserts }">
          <tbody v-if="desserts.length > 0">
            <tr v-for="row in desserts" :key="row.index">
              <!-- 結果 -->
              <td class="text-center">
                <div v-if="row.result == 'ok'">
                  <v-icon class="pa-1" color="success">
                    mdi-check-circle-outline
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon class="pa-1" color="error">
                    mdi-alert-circle-outline
                  </v-icon>
                </div>
              </td>
              <!-- 更新時刻 -->
              <td class="text-start">
                <div v-if="row.result == 'ok'">
                  <span class="success--text">
                    {{ utilDate.getDatetimeFormatLongDisplay(row.start_time) }}
                  </span>
                </div>
                <div v-else class="error--text">
                  <span>
                    {{ utilDate.getDatetimeFormatLongDisplay(row.start_time) }}
                  </span>
                </div>
              </td>
              <!-- コンテンツ名 -->
              <td class="text-start">
                <span class="text-subtitle-1">{{ row.site_content_name }}</span>
              </td>
              <!-- 媒体 -->
              <td class="text-start">
                <span
                  :style="row.color.length != 0 ? 'color:' + row.color : ''"
                  >{{ row.site_name }}</span
                >
              </td>
              <!-- カテゴリ -->
              <td class="text-start">
                <v-icon dense> {{ row.content_icon }} </v-icon
                ><span class="text-align-middle mini"
                  >&nbsp;{{ row.content_name }}</span
                >
              </td>
              <!-- メッセージ/補足 -->
              <td class="text-start">
                <span class="mini">{{ row.result_message }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="headers.length" style="text-align: center">
                表示するデータがありません
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "@vue/composition-api";
import { useRouter } from "@/utils";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  setup(_, ctx) {
    const { router } = useRouter();
    const resultLogsRepository = repositoryFactory.get("resultLogs");
    const state = reactive({
      hideWorking: true,
      search: "",
      headers: [
        {
          text: "結果",
          value: "result",
          sortable: true,
          align: "center",
          width: 60,
        },
        {
          text: "更新時刻",
          value: "start_time",
          sortable: true,
          width: 140,
        },
        {
          text: "コンテンツ名",
          value: "site_content_name",
          sortable: true,
          class: "td_site_content_name",
        },
        {
          text: "媒体",
          value: "site_name",
          sortable: true,
          class: "td_site_name",
        },
        {
          text: "タイプ",
          value: "content_name",
          sortable: true,
          class: "td_content_name",
        },
        {
          text: "メッセージ ／ 補足情報",
          value: "result_message",
          sortable: true,
          class: "td_result_message",
        },
      ],
      desserts: [], // DataTablesレコード格納用
    });

    // 初期化処理(同期処理)
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      if (!(await utilFunc.checkShop(currentShop.id, router))) return;

      // 更新結果取得
      state.desserts.splice(0);
      const now = new Date();
      const str_now = utilDate.getDatetimeFormatLong(now);
      const str_yesterday = utilDate.getDatetimeFormatLong(
        new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)
      );
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        s_datetime: str_yesterday,
        e_datetime: str_now,
        filter: state.hideWorking ? setting.content_id_working : null,
      };
      await resultLogsRepository
        .list(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              const site_name =
                response.data[key].site_name +
                (response.data[key].memo
                  ? "[" + response.data[key].memo + "]"
                  : "");
              state.desserts.push({
                "id": response.data[key].id ?? 0,
                "shop_id": response.data[key].shop_id,
                "shop_name": response.data[key].shop_name,
                "shop_site_id": response.data[key].shop_site_id,
                "site_name": site_name,
                "site_content_name": response.data[key].site_content_name,
                "content_id": response.data[key].content_id,
                "content_name": response.data[key].content_name,
                "content_icon": response.data[key].content_icon,
                "memo": response.data[key].memo,
                "color": response.data[key].color,
                "site_content_id": response.data[key].site_content_id,
                "result": response.data[key].result,
                "result_message": response.data[key].result_message,
                "start_time": utilDate.convertSplitStrToDate(
                  response.data[key].start_time
                ),
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:ShopSiteContent.vue/init ShopSiteContentRepository.list_shop (" +
            error +
            ")"
          );
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    const hideWorkingFilter = async () => {
      await init();
    };

    // 返却オブジェクト定義
    return {
      utilDate,
      setting,
      ...toRefs(state),
      init,
      hideWorkingFilter,
    };
  },
});
</script>
<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_site_content_name {
  min-width: 150px;
  width: 250px;
}
::v-deep .td_site_name {
  min-width: 200px;
  width: 250px;
}
::v-deep .td_content_name {
  min-width: 80px;
  width: 120px;
}
::v-deep .td_result_message {
  min-width: 250px;
}
</style>
